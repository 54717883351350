export const configuration = {
    about: {
        title: process.env.VUE_APP_TITLE ?? "Alt SC",
        support: process.env.VUE_APP_SUPPORT_EMAIL ?? "support@altsc.ru",
        version: process.env.APP_VERSION ?? "0.0.0",
    },
    api: {
        url: process.env.VUE_APP_API_BASEURL,
    },
    url: {
        webapp: process.env.VUE_APP_WEBAPP_BASEURL,
        docs: process.env.VUE_APP_DOCS_BASEURL,
        admin: process.env.VUE_APP_ADMIN_BASEURL,
    },
    prices: {
        free: {
            fee: 0,
            ordersCount: 10,
            salesCount: 10,
            tasksCount: 10,
        },
        standart: {
            fee: 20,
        },
        sc24: {
            fee: 15,
        },
    },
    integrations: {
        google: {
            /** Google Tag widget id. */
            widgetId: process.env.VUE_APP_GOOGLE_TAG_MANAGER_WIDGETID,
        },
        yandex: {
            /** Яндекс Метрика widget id. */
            widgetId: process.env.VUE_APP_YANDEX_METRIKA_WIDGETID,
        },
        jivo: {
            /** Jivo Site widget id. */
            widgetId: process.env.VUE_APP_JIVOSITE_WIDGETID,
        },
        vk: {
            /** ВК widget id. */
            widgetId: process.env.VUE_APP_VK_WIDGETID,
        },
        yoomoney: {
            /** ЮМани wallet id. */
            walletId: process.env.VUE_APP_YOOMONEY_WALLET_ID,
        },
        cloudPayments: {
            /** Cloud Payments public id. */
            publicId: process.env.VUE_APP_CLOUDPAYMENT_PUBLICID,
        },
    },
};
